export const enum ColorPalette {
  DARK_BLUE = "#00404D",
  BLUE = "#00758C",
  WHITE = "#FFFFFF",
  BLACK = "#282828",
  DARK_GRAY = "#24292E",
  LIGHT_GRAY = "#CCCCCC",
  LIGHTEST_GRAY = "#F7F9FB",
  LIGHT_BLUE = "#A1C3D1",
  GREEN = "#007A49",
  ERROR = "#D22B2B",
}

export const BaseTextFamily = "Helvetica, sans-serif";
export const AccentTextFamily = "Inter, sans-serif";

export const enum AnalyticEvent {
  BUY_NOW_CLICK = "BUY_NOW_CLICK",
  ADD_CART_CLICK = "ADD_CART_CLICK",
  CHANGE_PRODUCT_OPTION = "CHANGE_PRODUCT_OPTION",
  CONTACT_LINK_CLICK = "CONTACT_LINK_CLICK",
  INSTAGRAM_LINK_CLICK = "INSTAGRAM_LINK_CLICK",
  TWITTER_LINK_CLICK = "TWITTER_LINK_CLICK",
}

export const EMAIL_ADDRESS = "info@kirstenotting.photography";
export const MAIL_TO_LINK = `mailto:${EMAIL_ADDRESS}` as const;

export const POLICIES = {
  SHIPPING:
    "Shipping is free to US addresses. Please note that since prints are printed, inspected, and hand-packaged to order they may take up to two weeks to ship. You will be notified once your order has shipped.",
  SALES_TAX:
    "All prints and products are subject to your state sales tax when applicable. Sales tax will appear during checkout with my payment provider.",
  RETURNS:
    "Due to the bespoke nature of these prints, all sales are final - there are no returns or refunds unless the order has been damaged during shipping or is lost in transit. If damage or loss occurs, you must notify me within 30 days of the ship date to receive a replacement or refund.",
};

export const OrderStatusLabel: Record<ORDER_STATUS, string> = {
  IN_PROGRESS: "In Progress",
  SHIPPED: "Shipped",
  COMPLETE: "Complete",
};
