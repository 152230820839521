import { createStitches } from "@stitches/react";
import type * as Stitches from "@stitches/react";

// You should exports all properties
export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: {
    sm: "(min-width: 43em)",
    md: "(min-width: 62em)",
    lg: "(min-width: 82em)",
  },
});

export type CSS = Stitches.CSS<typeof config>;
