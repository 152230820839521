import React from "react";
import { styled } from "gatsby-theme-stitches/src/config";
import { Button } from "./buttons";
import { handleClickEventWithTracking, isBrowser } from "../utils";
import { AnalyticEvent, EMAIL_ADDRESS, MAIL_TO_LINK } from "../constants";

export const FullScreenError = () => {
  const handleReload = () => {
    if (isBrowser) {
      window.location.reload();
    }
  };
  return (
    <Container>
      <ErrorMessage>
        Sorry.. there was an error. Please try again. Please contact me at{" "}
        <a
          href={MAIL_TO_LINK}
          onClick={handleClickEventWithTracking(
            AnalyticEvent.CONTACT_LINK_CLICK
          )}
        >
          {EMAIL_ADDRESS}
        </a>{" "}
        if you continue to experience issues.
        <Button onClick={handleReload}>Reload</Button>
      </ErrorMessage>
    </Container>
  );
};
const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
});

const ErrorMessage = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "50vh",
  justifyContent: "center",
  alignContent: "center",
});
