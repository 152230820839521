import { ImageDataLike } from "gatsby-plugin-image";
import { createContext } from "react";
import { LocalStorageState } from "./hooks/persisted-state.hook";

export const CartContext = createContext<LocalStorageState<ClientCartItem[]>>([
  [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  { isPersistent: false, removeItem: () => {} },
]);

export const addToCart = (
  itemId: string,
  quantityChange: number,
  existingCart: ClientCartItem[],
  setStateFunc: (updatedCart: ClientCartItem[]) => void
) => {
  const itemInCart = existingCart.some((c: ClientCartItem) => c.id === itemId);
  if (itemInCart) {
    const updatedCart: ClientCartItem[] = existingCart.map(
      (c: ClientCartItem) => ({
        ...c,
        ...(c.id === itemId ? { quantity: c.quantity + quantityChange } : {}),
      })
    );
    const updatedCartWithoutZeros = updatedCart.filter((c) => c.quantity > 0);
    setStateFunc(updatedCartWithoutZeros);
  } else {
    const updatedCart: ClientCartItem[] = [
      ...existingCart,
      {
        id: itemId,
        quantity: quantityChange,
      },
    ];
    setStateFunc(updatedCart);
  }
};

export const removeFromCart = (
  itemId: string,
  existingCart: ClientCartItem[],
  setStateFunc: (updatedCart: ClientCartItem[]) => void
) => {
  const updatedCart = existingCart.filter((c) => c.id !== itemId);
  setStateFunc(updatedCart);
};

export const checkout = async (data: {
  items: Array<{ id: string; quantity: number }>;
}) => {
  try {
    const result = await fetch(`/checkout-api`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (result.status === 400) {
      const error: { errorCode: number; message: string } = await result.json();
      return {
        message: "Could not process order. Error code: " + error.errorCode,
      };
    }

    const checkoutResponse: CheckoutResponse = await result.json();
    if (checkoutResponse?.url) {
      window.location.assign(checkoutResponse.url);
    } else {
      throw new Error(
        `No checkout link returned: ${JSON.stringify(checkoutResponse)}`
      );
    }
  } catch (err) {
    console.error(`Error checking out`, err);
  }
};

export type ClientCartItem = { id: string; quantity: number };

export type CatalogItem = {
  id: string;
  title: string;
  subtitle: string;
  price: number;
  thumbnail: ImageDataLike;
  slug: string;
};
