import * as Swetrix from "swetrix";
import { AnalyticEvent } from "./constants";
import { Slide, GatsbySlide } from "yet-another-react-lightbox";

export const logAnalyticEvent = (eventName: AnalyticEvent) => {
  const ev: string = eventName;
  Swetrix.track({ ev });
};

export const handleClickEventWithTracking =
  (eventName: AnalyticEvent) => (event: React.MouseEvent<HTMLElement>) => {
    logAnalyticEvent(eventName);
  };

export const isBrowser = typeof window !== "undefined";

export const formatDollars = (number: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

// number in seconds unix epoch
export const formatDateTime = (number: number) => {
  const date = new Date(number * 1000);
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "short",
    timeStyle: "short",
  }).format(date);
};

export const formatDateLong = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "long",
  }).format(date);
};

export const getYear = (dateString: string) => {
  const date = new Date(dateString);
  return date.getFullYear();
};

export const formatTrackingLink = (
  shippingType?: string,
  trackingNumber?: string
) => {
  if (!trackingNumber) return;
  switch (shippingType) {
    case "USPS": {
      return `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingNumber}`;
    }
    case "UPS": {
      return `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${trackingNumber}`;
    }
    default:
      return;
  }
};

export const isGatsbySlide = (slide: Slide): slide is GatsbySlide => {
  return slide.type === "gatsby-slide";
};
