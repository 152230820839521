import { Link } from "gatsby";
import { ColorPalette } from "../constants";
import { CSS, styled } from "gatsby-theme-stitches/src/config";

const outlined: CSS = {
  marginBlock: "1em",
  paddingBlock: "1em",
  paddingInline: "2em",
  color: ColorPalette.BLACK,
  backgroundColor: "transparent",
  border: `2px solid ${ColorPalette.BLACK}`,
  borderRadius: "2px",
  textAlign: "center",
  "&:hover": {
    color: ColorPalette.BLUE,
    border: `2px solid ${ColorPalette.BLUE}`,
  },
};

const contained: CSS = {
  marginBlock: "1em",
  paddingBlock: "1em",
  paddingInline: "2em",
  backgroundColor: ColorPalette.BLUE,
  color: ColorPalette.WHITE,
  border: "none",
  textAlign: "center",
  "&:hover": {
    opacity: ".8",
    color: ColorPalette.WHITE,
  },
};

const small: CSS = { marginBlock: "1em", padding: "0.5em" };
const med: CSS = {
  marginBlock: "1em",
  paddingBlock: "1em",
  paddingInline: "2em",
};
const large: CSS = {
  marginBlock: "1.5em",
  paddingBlock: "1.5em",
  paddingInline: "2em",
};

const variants = {
  variants: {
    variant: {
      outlined: {
        ...outlined,
      },
      contained: {
        ...contained,
      },
    },
    spacing: {
      small: {
        ...small,
      },
      med: {
        ...med,
      },
      large: {
        ...large,
      },
    },
  },
  defaultVariants: {
    variant: "contained",
    spacing: "small",
  },
};

const ButtonLink = styled("a", {
  ...variants,
});

const ButtonLinkInternal = styled(Link, {
  ...variants,
});

const Button = styled("button", {
  ...variants,
  cursor: "pointer",
});

export { ButtonLink, ButtonLinkInternal, Button };
