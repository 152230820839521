import React from "react";

import { ErrorBoundary } from "react-error-boundary";
import { FullScreenError } from "./src/components/fullscreen-error";
import { CartContext, ClientCartItem } from "./src/cart";
import useLocalStorageState from "./src/hooks/persisted-state.hook";
export const onRenderBody = (gatsbyUtils) => {
  const { setHtmlAttributes } = gatsbyUtils;

  setHtmlAttributes({ lang: "en" });
};

const stableDefaultValue: ClientCartItem[] = [];

const LocalStorageCart = ({ children }) => {
  const cartState = useLocalStorageState<ClientCartItem[]>("cart", {
    defaultValue: stableDefaultValue,
  });

  return (
    <CartContext.Provider value={cartState}>{children}</CartContext.Provider>
  );
};

export const wrap = ({ element }) => {
  return (
    <ErrorBoundary fallback={<FullScreenError />}>
      <LocalStorageCart>{element}</LocalStorageCart>
    </ErrorBoundary>
  );
};
